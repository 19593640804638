<template>
    <div class="components-container">
        <aside>
            {{ $t("game_notice.tips") }}
            <a target="_blank" class="link-type" href="http://tinymce.ax-z.cn/">
                {{ $t("components.documentation") }}
            </a>
            <el-date-picker v-model="publishTime" style="margin-left: 30%;" type="datetime" format="YYYY-MM-DD HH:mm:ss"
                placeholder="publish time" />
            <el-button v-waves type="success" icon="el-icon-circle-plus" @click="handlerSureNotice">
                {{ $t("game_notice.apply") }}
            </el-button>
        </aside>
        <div>
            <Tinymce v-if="tinymceActive" v-model:value="content" :height="`400`" @input="input" />
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <div class="editor-content" v-html="content" />
    </div>
</template>
  
<script lang="ts">
import { GameManage_GMAddNewServerNotice } from '@/apis/game_manage';
import Tinymce from '@/components/tinymce/Index.vue';
import { TodayStart } from '@/utils/timer';
import { ElMessage, ElMessageBox } from 'element-plus';
import {
defineComponent,
onActivated,
onDeactivated,
reactive, toRefs
} from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    components: {
        Tinymce
    },
    setup() {
        const { t } = useI18n()
        const dataMap = reactive({
            content: `<h1 style="text-align: center;">Welcome to the TinyMCE demo!</h1>
            <ul><li>Our <a href="//www.tinymce.com/docs/">documentation</a> is a great resource for learning how to configure TinyMCE.</li><li>Have a specific question? Visit the <a href="https://community.tinymce.com/forum/">Community Forum</a>.</li><li>We also offer enterprise grade support as part of <a href="https://tinymce.com/pricing">TinyMCE premium subscriptions</a>.</li>
      </ul>`,
            tinymceActive: true,
            publishTime: TodayStart(),
            input(value: any) {
                dataMap.content = value
            }
        })
        const pickerStartOptions = {
            disabledDate(time: Date) {
                return time.getTime() - 7 * 24 * 3600 * 1000 > Date.now();
            }
        };
        const handlerSureNotice = () => {
            if (dataMap.content.length == 0) {
                return;
            }
            ElMessageBox.confirm(
                `Confirm to this Notice Publish at  ${dataMap.publishTime} ?`,
                `Notice Publish`,
                {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    type: "success"
                }
            ).then(async () => {
                let msg = {} as IC2G_GMAddNewServerNotice;
                msg.NOTICE = dataMap.content;
                msg.OperateTime = Math.ceil(
                    dataMap.publishTime.getTime() / 1000
                );

                let cbmsg = await GameManage_GMAddNewServerNotice(msg);
                if (cbmsg?.Error == 0) {
                    ElMessage.success(`Notice Publish Success`);
                } else {
                    ElMessage.error(`Notice Publish Fail`);
                }
            }).catch(err => {
                console.error(err);
            });
        }

        onDeactivated(() => {
            dataMap.tinymceActive = false
        })
        onActivated(() => {
            dataMap.tinymceActive = true
        })

        return {
            t, ...toRefs(dataMap),
            pickerStartOptions,
            handlerSureNotice
        }
    }
})

</script>
  
<style lang="scss" scoped>
.editor-content {
    margin-top: 20px;
}
</style>
  