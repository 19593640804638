/*
 * @Description: 游戏管理
 * @Author: ZY
 * @Date: 2020-12-28 14:40:50
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-20 10:17:09
 */
import https from '@/utils/https'
import { ContentType, Method } from 'axios-mapper'

export const GameManage_GMAddNewServerNotice = (data: IC2G_GMAddNewServerNotice) => {
    return https().request<IHttpResponse>('/GMAddNewServerNotice', Method.POST, data, ContentType.json)
}

export function GameManage_GMProcessEdit(data: IC2G_GMProcessEdit) {
    return https().request<IHttpResponse>('/GMProcessEdit', Method.POST, data, ContentType.json)
}

export function GameManage_GMGetServerKeyList() {
    return https().request<IHttpResponse>('/GMGetServerKeyList', Method.GET, {}, ContentType.json)
}

export function GameManage_GMSearchClientErrorLog(data: IC2G_GMSearchClientErrorLog) {
    return https().request<IG2C_GMSearchLog>('/GMSearchClientErrorLog', Method.POST, data, ContentType.json)
}

export function GameManage_GMSearchClientSuggest(data: IC2G_GMSearchClientSuggest) {
    return https().request<IG2C_GMSearchLog>('/GMSearchClientSuggest', Method.POST, data, ContentType.json)
}
